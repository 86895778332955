.counter {
    display: flex;
    align-items: center;

    color: gray;
}

.minus {
    margin-right: 7px;
    margin-top: 2px;
}

.plus {
    margin-left: 7px;
    margin-top: 2px;
}
