.item {
    display: flex;
    flex-direction: column;

    width: 100%;

    /* margin-left: auto;
    margin-right: auto; */
}

.img {
    border-radius: 12px 12px 0 0;
    width: 100%;
    /* height: 100%; */
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    padding: 12px 10px;
}

.title {
    font-size: 13px;
    text-align: left;
}

.subtitle {
    font-size: 9px;
    text-align: left;
    font-style: italic;

    margin-bottom: 10px;
    margin-top: 3px;
}

.box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 27px;
}

.price {
    text-align: left;
    font-size: 12px;
    font-weight: bold;

    color: var(--color-active);
}

.plus {
    margin-top: 2px;
}
