.nav {
    padding: 7px 5px 7px 0;
}

.item {
    outline: none;

    width: 100%;
    margin: 10px;

    font-size: 17px;
}

.item a {
    padding: 2px;
    outline: none;

    color: white;
}

.activeLink a {
    border-top: 1px solid var(--color-active);
    border-bottom: 1px solid var(--color-active);
    outline: none;

    color: var(--color-active);
}
