.dishes {
    position: relative;
    overflow: scroll;

    padding-top: 15px;
    padding-bottom: 60px;

    /* left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: slideIn 0.4s; */
}

/* @keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
} */
