.orderBlock {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    /* margin-right: -50%; */

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    padding: 0 10px;
    width: 265px;
    height: 52px;

    border-radius: 60px;
    border-color: var(--color-active);
    background-color: var(--color-bcg);
    color: white;
}

.sum {
    color: var(--color-active);
    font-weight: bold;
}
