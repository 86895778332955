.overlay {
    position: fixed;
    top: 50%;
    left: 50%;

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);

    z-index: 1200;
    transform: translate(-50%, -50%);
}

.overlayConent {
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    width: 60%;
    padding: 10px;

    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.text {
    margin-top: 10px;
    font-size: 16px;
}

.btn {
    display: block;
    width: 160px;
    border: none;
    border-radius: 4px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 5px;
    padding-top: 8px;
    padding-bottom: 8px;

    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.06em;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    background: var(--color-active);
    color: white;
}
