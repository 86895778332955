.basket {
    position: relative;
    overflow: scroll;
}

.item {
    display: flex;
    padding: 20px;
}

.description::after {
    content: '';

    width: 100%;
    height: 1px;

    background: lightgray;
}
.description {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    margin-left: 15px;
    width: 100%;
    min-height: 70px;
}

.title {
    margin-top: 20px;
    text-align: center;

    color: var(--color-active);
}

.img {
    display: block;
    border-radius: 50%;

    width: 80px;
    height: 70px;
}

.info {
    display: flex;
    align-items: center;

    color: gray;
}

.delete {
    margin-left: auto;
    transform: rotate(45deg);
}

.totalAmmount {
    margin-top: 15px;
    margin-left: 20px;
    font-weight: bold;
}

.form {
    display: flex;
    flex-direction: column;
}

.label {
    margin-left: 20px;
    margin-top: 20px;
}

.input {
    border: 1px solid rgba(33, 33, 33, 0.2);
    border-radius: 4px;
    width: 25px;

    padding: 5px;
    margin-left: 5px;

    cursor: pointer;

    transition-property: border-color;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus {
    outline: none;
    border: 1px solid var(--color-active);
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 160px;
    height: 36px;
    border: none;
    border-radius: 4px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 100px;
    padding: 0px;

    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.06em;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    background: var(--color-active);
    color: white;
}

.btn:disabled {
    background-color: lightgray;
}

.commentSpan {
    margin-left: 20px;
    margin-top: 20px;
}

.commentText {
    margin-top: 5px;
    margin-left: 20px;

    width: 70%;
    height: 70px;

    border: 1px solid rgba(33, 33, 33, 0.2);
    border-radius: 4px;
}

.commentText:focus {
    outline: none;
    border: 1px solid var(--color-active);
}

.callBox {
    display: flex;
    align-items: center;

    margin-left: 20px;
    margin-top: 20px;
}

.callText {
    font-style: italic;
    font-size: 12px;
}

.iconTel {
    margin-left: 5px;
}

.price {
    margin-left: 10px;
}
