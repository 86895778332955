header {
    display: flex;
    align-items: center;
}

header span {
    color: white;
}

.logo {
    display: block;
    width: 55px;
    height: 55px;

    margin-left: 43%;
    margin-top: 5px;
    margin-right: auto;
}

.en {
    margin-left: 7px;
}

.back {
    position: absolute;
    left: 15px;
}
