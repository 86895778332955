.menu-title {
    text-align: center;
    padding-top: 10px;
    font-weight: normal;

    color: var(--color-active);
}

.menu-list {
    display: flex;
    flex-wrap: wrap;

    margin-top: 20px;
    padding: 0 5px;
}

.menu-item {
    display: flex;
    flex-basis: calc((100% - 20px) / 2);
    text-align: center;

    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;

    width: 45%;

    box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
    border-radius: 10px;
}

img {
    width: 100%;
    /* height: 100%; */
}

.slick-next:before,
.slick-prev:before {
    content: '';
}

input#switch + label::before {
    background-color: var(--color-active);
}

input#switch + label {
    background-color: var(--color-active);
}

.breakfast {
    margin-top: 2px;
    font-style: italic;
    font-size: 9px;
    text-align: center;
    color: gray;
}
